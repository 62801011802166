import { useIsItOpentalentCompanyUser } from 'hooks/company';
import { useSnackbar } from 'notistack';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

import {
  Talent,
  TalentCompanyPoolConnection,
  TalentCompanyPoolingInitiatorEnum,
  TalentCompanyPoolingStatusEnum,
  useApproveTalentCompanyPoolApplicationMutation,
  useRevokeCompanyPoolInvitationMutation,
  useRemoveTalentFromCompanyPoolMutation,
  useInviteTalentToCompanyPoolMutation,
} from '@libs/graphql-types';

import { ActionItem } from '../types';
import { useOpenAcceptTalentModal } from './modals/AcceptTalentModal';
import { useOpenRejectTalentModal } from './modals/RejectTalentModal';
import { useOpenRemoveTalentModal } from './modals/RemoveTalentModal';

export const useCompanyPoolActions = ({
  talent,
  connection,
  onSuccess,
}: {
  talent: Talent;
  connection?: TalentCompanyPoolConnection;
  onSuccess?: VoidFunction;
}) => {
  const isCurrentUserFromOpenTalent = useIsItOpentalentCompanyUser();
  const { enqueueSnackbar } = useSnackbar();
  const [, { loading: isConnecting }] = useInviteTalentToCompanyPoolMutation({
    variables: { talent_id: talent.id },
    onCompleted: () => {
      onSuccess?.();
      enqueueSnackbar('Talent has been added to your community.', {
        variant: 'success',
      });
    },
  });

  const [, { loading: isRemoving }] = useRemoveTalentFromCompanyPoolMutation({
    variables: { talent_id: talent.id },
    onCompleted: () => {
      enqueueSnackbar('Talent has been removed from your community.', {
        variant: 'success',
      });
      onSuccess?.();
    },
  });
  const openAcceptTalentModal = useOpenAcceptTalentModal();
  const openRemoveTalentModal = useOpenRemoveTalentModal();
  const openRejectTalentModal = useOpenRejectTalentModal();
  const [, { loading: isApproveing }] =
    useApproveTalentCompanyPoolApplicationMutation({
      variables: { talent_id: talent.id },
      onCompleted: () => {
        enqueueSnackbar('Talent has been added to your community.', {
          variant: 'success',
        });
        onSuccess?.();
      },
    });
  const [revoke, { loading: isRevoking }] =
    useRevokeCompanyPoolInvitationMutation({
      variables: { talent_id: talent.id },
      onCompleted: () => {
        enqueueSnackbar('Invitation has been revoked.', {
          variant: 'success',
        });
        onSuccess?.();
      },
    });
  const actions: ActionItem[] = [];

  const isLoadingFinal =
    isRevoking || isApproveing || isRemoving || isConnecting;

  if (connection?.status === TalentCompanyPoolingStatusEnum.Pending) {
    if (
      [
        TalentCompanyPoolingInitiatorEnum.Talent,
        TalentCompanyPoolingInitiatorEnum.System,
      ].includes(connection.initiator as any)
    ) {
      actions.push({
        text: 'Accept',
        Icon: CheckCircleOutlineOutlinedIcon,
        onClick: () => openAcceptTalentModal({ talent }),
        color: 'success',
      });

      actions.push({
        text: 'Reject',
        Icon: HighlightOffOutlinedIcon,
        onClick: () => openRejectTalentModal({ talent }),
        color: 'error',
      });
    }

    if (connection.initiator === TalentCompanyPoolingInitiatorEnum.Company) {
      actions.push({
        text: 'Cancel request',
        Icon: CheckCircleOutlineOutlinedIcon,
        onClick: revoke,
        color: 'error',
      });
    }
  }

  if (
    connection?.status === TalentCompanyPoolingStatusEnum.Approved &&
    !isCurrentUserFromOpenTalent
  ) {
    actions.push({
      text: 'Remove',
      Icon: DeleteOutlinedIcon,
      onClick: () => openRemoveTalentModal({ talent }),
      color: 'error',
    });
  }

  if (connection?.status === TalentCompanyPoolingStatusEnum.Rejected) {
    actions.push({
      text: 'Connect',
      Icon: PersonAddAltOutlinedIcon,
      onClick: () => openAcceptTalentModal({ talent }),
      color: 'success',
    });
  }

  if (connection?.status === TalentCompanyPoolingStatusEnum.Cancelled) {
    actions.push({
      text: 'Connect',
      Icon: PersonAddAltOutlinedIcon,
      onClick: () => openAcceptTalentModal({ talent }),
      color: 'success',
    });
  }

  return { actions, isLoadingFinal };
};
