import { pathManager } from 'routes';

import {
  NotificationActionRedirect,
  NotificationActionTypeEnum,
  RedirectTargetRouteTypeEnum,
  NotificationActionInterface,
  GetNotificationsQuery,
} from '@libs/graphql-types';

const getRedirectLink = (action: NotificationActionRedirect) => {
  switch (action.target_route_type) {
    case RedirectTargetRouteTypeEnum.TalentJobInvitation:
      return pathManager.talent.jobInvitation.generatePath({
        id: action.target_route_id,
      });
    case RedirectTargetRouteTypeEnum.CompanyUserJob:
      return pathManager.company.job.generatePath({
        id: action.target_route_id,
      });
    case RedirectTargetRouteTypeEnum.TalentJob:
      return pathManager.talent.jobApply.generatePath({
        id: action.target_route_id,
      });
    case RedirectTargetRouteTypeEnum.TalentProfile:
      return pathManager.company.talentProfile.generatePath({
        id: action.target_route_id,
      });
    default:
      // eslint-disable-next-line no-console
      console.error('Unknown redirect type', action);
      return '';
  }
};

export const getActionLink = (
  action: NotificationActionInterface | NotificationActionRedirect,
) => {
  switch (action.type) {
    case NotificationActionTypeEnum.Redirect:
      return getRedirectLink(action as NotificationActionRedirect);
    case NotificationActionTypeEnum.Confirm:
      return getRedirectLink(action as NotificationActionRedirect);
    default:
      // eslint-disable-next-line no-console
      console.error('Unknown action type', action);
      return '';
  }
};

export type NotificationListType = NonNullable<
  GetNotificationsQuery['currentUserNotifications']
>['data'];
export type NotificationItemType = NotificationListType[number];
