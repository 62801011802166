import {
  differenceInHours,
  format,
  isFuture,
  isPast,
  parseISO,
} from 'date-fns';

import { CurrencyEnum, Job, Skill, SkillTypeEnum } from '@libs/graphql-types';
import { isNumber } from '@libs/helpers/common';
import { isNil } from '@libs/helpers/common';
import { formatNumber } from '@libs/helpers/format';
import { JOB_TYPE_MAP } from '@libs/ui/components/job/utils/consts';

import { JOB_EXPIRE_HOURS_THRESHOLD } from './consts';

export const formatDate = (date: string) =>
  date ? format(parseISO(date), 'dd MMM yyyy') : '';

export const sortSkills = (skills: Skill[] = []) =>
  skills.reduce<Record<SkillTypeEnum, Skill[]>>(
    (acc, skill) => {
      acc[skill.skill_type].push(skill);
      return acc;
    },
    {
      [SkillTypeEnum.HardSkills]: [],
      [SkillTypeEnum.SoftSkills]: [],
      [SkillTypeEnum.Solutions]: [],
    },
  );

const thousandsSeparatorRegExp = RegExp(/\B(?=(\d{3})+(?!\d))/g);

export const formatFloatRate = (s: string | number = ''): string =>
  s?.toString()?.replace('.', ',').replace(thousandsSeparatorRegExp, '.');

const CURRENCY_SIGN_MAP = {
  [CurrencyEnum.Eur]: '€',
  [CurrencyEnum.Usd]: '$',
};

export const formatRate = ({
  min,
  max,
  isNegotiable = false,
  period,
  currency = CurrencyEnum.Eur,
}: {
  min?: number;
  max?: number;
  currency?: CurrencyEnum;
  isNegotiable?: boolean;
  period?: 'hour' | 'month' | 'monthSilently';
}): string | undefined => {
  if ([min, max].some((i) => !isNil(i))) {
    const currencySign = CURRENCY_SIGN_MAP[currency] || '€';
    const interval = [min, max]
      .filter((i) => !!i)
      .map((i) => (isNumber(i) ? formatNumber(i) : i))
      .join('-');
    const periodLabels = {
      hour: 'p/h',
      month: 'p/m',
      monthSilently: '',
    };
    const currencyWithPeriod = period ? periodLabels[period] : '';

    return `${currencySign}${interval} ${
      currencyWithPeriod ? `${currencyWithPeriod}` : ''
    } ${isNegotiable ? ' (negotiable)' : ''}`;
  }
  return undefined;
};

export enum CampaignStatus {
  NotStarted = 'not-started',
  Started = 'started',
  Finished = 'finished',
  Archived = 'archived',
  Draft = 'draft',
}

export const getCampaignStatus = (job?: Job): CampaignStatus => {
  if (job?.is_draft) {
    return CampaignStatus.Draft;
  }

  const startDate = parseISO(job?.campaign_start_date);
  const endDate = parseISO(job?.campaign_end_date);

  if (!job) {
    // eslint-disable-next-line no-console
    console.info('🚀 ~ getCampaignStatus - not job provided', job);
    return CampaignStatus.NotStarted;
  }
  if (job?.is_archived) {
    return CampaignStatus.Archived;
  }
  if (isPast(endDate)) {
    return CampaignStatus.Finished;
  }
  if (isPast(startDate) && isFuture(endDate)) {
    return CampaignStatus.Started;
  }
  return CampaignStatus.NotStarted;
};

export const isUnprocessableJob = (job?: Job) =>
  [CampaignStatus.Finished, CampaignStatus.Archived].includes(
    getCampaignStatus(job),
  );

export const getJobCapacity = (job: Job) => {
  const value = job.is_old_format ? job.capacity : job.hours_per_week;
  return value ? `${value} hours/week` : '';
};

export const getJobInfoItems = (job: Job) => [
  ...([job?.rate_min, job?.rate_max].some((i) => i !== undefined)
    ? [
        {
          label: 'Rate:',
          value: formatRate({
            min: job?.rate_min,
            max: job?.rate_max,
            isNegotiable: job.is_rate_negotiable,
            currency: CurrencyEnum.Eur,
          }),
        },
      ]
    : []),
  { label: 'Starting date:', value: formatDate(job.start_date) },
  { label: 'End date:', value: formatDate(job.end_date) },
  {
    label: 'Capacity:',
    value: getJobCapacity(job),
  },
  { label: 'Type:', value: (JOB_TYPE_MAP as any)[job.location_type || ''] },
  { label: 'Client:', value: job.client || '-' },
];

export const getDiffHours = (endCampaignDay: string): number => {
  const endDate = parseISO(endCampaignDay);
  const campaignDiffHours = differenceInHours(endDate, new Date());

  return campaignDiffHours;
};

export const checkJobRemainHours = (hours: number): boolean =>
  hours > 0 && hours < JOB_EXPIRE_HOURS_THRESHOLD;
