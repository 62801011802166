import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  DefaultModalProps,
  useOpenModal,
  withLocationStateModal,
} from 'utils/modals';
import { formatName } from 'utils/talent';

import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Talent,
  useRemoveTalentFromCompanyPoolMutation,
} from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';
import { FormikSubmit } from '@libs/helpers/form';
import Button from '@libs/ui/components/button';
import { ConnectedTextField } from '@libs/ui/components/form/text-field';
import { DefaultModal } from '@libs/ui/components/modals';

interface ModalStateData {
  talent: Talent;
}

interface LimitedProfileAccessModalProps
  extends DefaultModalProps<ModalStateData> {}

interface FormikState {
  message: string;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '585px',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
}));

const useSubmitAction = ({
  talent,
  onSuccess,
}: {
  talent: Talent | undefined;
  onSuccess: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const talentId = talent?.id;
  const [remove, { loading: isRemoving }] =
    useRemoveTalentFromCompanyPoolMutation({
      onCompleted: () => {
        enqueueSnackbar('Talent has been removed from your community.', {
          variant: 'success',
        });
        onSuccess?.();
      },
    });

  const onSubmit: FormikSubmit<FormikState> = async ({ message }) => {
    if (!talentId) {
      enqueueSnackbar('No talent id provided', { variant: 'error' });
      return;
    }

    remove({ variables: { talent_id: talentId, message } });
  };

  return { isLoading: isRemoving, onSubmit };
};

const RemoveTalentModalComponent = ({
  modalData,
  isOpen,
  close,
}: LimitedProfileAccessModalProps) => {
  const classes = useStyles();
  const talent = modalData?.talent;
  const { isLoading, onSubmit } = useSubmitAction({
    talent,
    onSuccess: close,
  });
  const talentName = formatName({
    firstName: modalData?.talent?.first_name_abac?.value,
    lastName: modalData?.talent?.last_name_abac?.value,
  });

  const defaultText = `\
Dear ${talentName || 'talent'},
We regret to inform you that, after careful consideration, we have decided to remove you from our Community.
We appreciate the time you spent with us and wish you the best in your future endeavors.
Sincerely,
`;

  return (
    <Formik<FormikState>
      onSubmit={onSubmit}
      initialValues={{ message: defaultText }}
    >
      {({ submitForm }) => (
        <DefaultModal
          handleClose={close}
          open={isOpen}
          className={classes.modal}
          title="Removing talent from Community"
          actions={
            <Grid spacing={4} container>
              <Grid xs={6} item>
                <Button
                  fullWidth
                  size="large"
                  color="error"
                  variant="contained"
                  disabled={isLoading}
                  onClick={submitForm}
                >
                  Remove talent
                </Button>
              </Grid>
              <Grid xs={6} item>
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="info"
                  onClick={close}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Box mt={4}>
            <Typography variant="body1">
              Are you sure you want to remove this talent from the community?
              You may want to write a message to inform them.
            </Typography>
          </Box>
          <Box mt={6}>
            <ConnectedTextField
              fullWidth
              size="small"
              variant="filled"
              multiline
              label="Message text"
              name={modelPath<FormikState>((m) => m.message)}
              rows={6}
            />
          </Box>
        </DefaultModal>
      )}
    </Formik>
  );
};
RemoveTalentModalComponent.id = 'RemoveTalentModal';

export const RemoveTalentModal = withLocationStateModal<ModalStateData>({
  id: RemoveTalentModalComponent.id,
  getModalKey: (data) => data?.talent?.id,
})(RemoveTalentModalComponent);

export const useOpenRemoveTalentModal = () =>
  useOpenModal(RemoveTalentModalComponent.id);
