import { ChatTypes } from 'components/chat';
import { useMessengerStatus } from 'components/chat/hooks';
import { useCompanyPoolActions } from 'components/custom/company/talent-pool-actions/hooks';
import { useRemindAccount } from 'hooks/account/useRemindAccount';
import { useRevokeInviteAccount } from 'hooks/account/useRevokeInviteAccount';
import { useGetCompanyPoolConnectionToTalent } from 'hooks/company';
import { useHaveAccessToTalent } from 'hooks/talents';
import { useHistory } from 'react-router-dom';
import { pathManager } from 'routes';
import { formatName, getIsVerifiedTalent } from 'utils/talent';

import AddCircle from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import { Talent } from '@libs/graphql-types';

import { ActionItem } from '../../types';
import { useOnInviteAction } from './useOnInviteAction';

interface Args {
  onSuccess?: VoidFunction;
  talent: Talent;
}

const useNotAcceptedActions = ({ onSuccess, talent }: Args) => {
  const { remindTalent, loading } = useRemindAccount(talent, onSuccess);
  const { loading: isRevoking, revokeInvite } = useRevokeInviteAccount(
    talent,
    onSuccess,
  );

  const actions: ActionItem[] = [
    {
      text: 'Send reminder',
      Icon: CheckCircleOutlineIcon,
      onClick: remindTalent,
      disabled: loading,
    },
    {
      text: 'Revoke invite',
      Icon: NotInterestedIcon,
      onClick: revokeInvite,
      disabled: isRevoking,
    },
  ];

  return actions;
};

const useCommonTalentActions = ({ onSuccess, talent }: Args) => {
  const history = useHistory();
  const isTalentVerified = getIsVerifiedTalent(talent);
  const hasAccess = useHaveAccessToTalent(talent as Talent);
  const isEnabled = useMessengerStatus(talent.stream_chat_id || '');
  const connection = useGetCompanyPoolConnectionToTalent({ talent });
  const { actions: poolActions } = useCompanyPoolActions({
    talent,
    onSuccess,
    connection,
  });

  const onInvite = useOnInviteAction({
    talentId: talent.id,
    talentName: formatName({
      firstName: talent.first_name_abac?.value,
      lastName: talent.last_name_abac?.value,
    }),
  });

  const actions: ActionItem[] = [
    {
      text: 'Invite to apply',
      Icon: AddCircle,
      onClick: onInvite,
      disabled: !isTalentVerified,
    },
    ...poolActions,
  ];

  if (hasAccess && isEnabled) {
    const startChat = () =>
      history.push({
        pathname: pathManager.chat.generatePath(),
        state: {
          strategy: {
            type: ChatTypes.TalentToTalent,
            data: talent,
          },
        },
      });

    actions.push({
      text: 'Message',
      Icon: MessageOutlinedIcon,
      onClick: startChat,
      disabled: !connection || !isTalentVerified,
    });
  }

  return actions;
};

export const useTalentActions = ({ talent, onSuccess }: Args) => {
  const connections = useGetCompanyPoolConnectionToTalent({ talent });
  const isNotAccepted = connections?.status !== 'APPROVED';

  let finalActions = null;
  const notAcceptedActions = useNotAcceptedActions({ talent, onSuccess });
  const commonActions = useCommonTalentActions({ talent, onSuccess });

  if (isNotAccepted) {
    finalActions = notAcceptedActions;
  } else {
    finalActions = commonActions;
  }

  return finalActions;
};
