import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { useDynamicChatDataContext } from 'components/chat/common/provider';
import { DrawerProps } from 'components/layout/drawer';
import {
  DrawerItemBadge,
  DrawerListItemProps,
} from 'components/layout/drawer/drawer-list-item';
import { HeaderProps } from 'components/layout/header';
import { format, parseISO } from 'date-fns';
import { useCurrentUser } from 'hooks/auth';
import { useAuth0 } from 'hooks/auth/useAuth0';
import {
  useIsCurrentTalentVerified,
  useIsPaidMatcherAccount,
  useIsPaidMemberAccount,
} from 'hooks/talents/useTalentAccountType';
import { useMemo } from 'react';
import {
  formatName,
  getFirstName,
  getLastName,
  getIsLobbyTalent,
  getIsVerifiedTalent,
} from 'utils/talent';

import { Chip } from '@mui/material';

import { CompanyAccountTypeEnum, Talent, User } from '@libs/graphql-types';

import {
  NOT_ACCEPTED_TALENT_ITEMS,
  MESSENGER_ITEM,
  COMPANY_COMMUNITY_ITEM,
  COMPANY_JOB_BOARD_ITEM,
  DEFAULT_ENTERPRISE_COMPANY_ITEMS,
  getDefaultTalentItems,
} from './consts';

const getTalentDrawerItems = (talent?: Talent) => {
  const isAccepted = talent?.is_invitation_accepted ?? false;
  const isTalentVerified = getIsVerifiedTalent(talent);
  const isFreeAccount = getIsLobbyTalent(talent);
  const items = !isAccepted
    ? NOT_ACCEPTED_TALENT_ITEMS
    : getDefaultTalentItems({ isFreeAccount, isTalentVerified });
  return items;
};
const getCompanyUserDrawerItem = (companyUser?: User) => {
  const isEnterprise =
    companyUser?.company?.account_type === CompanyAccountTypeEnum.Enterprise;

  if (isEnterprise) {
    return DEFAULT_ENTERPRISE_COMPANY_ITEMS;
  }
  return [COMPANY_JOB_BOARD_ITEM, COMPANY_COMMUNITY_ITEM];
};

const useConnectedDrawerProps = (): DrawerListItemProps[] => {
  const { isTalent, data } = useCurrentUser();
  const { unreadMessageCount } = useDynamicChatDataContext();
  const items = useMemo(() => {
    return isTalent
      ? getTalentDrawerItems(data?.currentTalent)
      : getCompanyUserDrawerItem(data?.currentCompanyUser);
  }, [isTalent, data?.currentTalent, data?.currentCompanyUser]);
  const appliedMessengerItem = items.find(
    (i) => i.text === MESSENGER_ITEM.text,
  );

  if (!appliedMessengerItem) {
    items.push({
      ...MESSENGER_ITEM,
      badge: !!unreadMessageCount ? (
        <DrawerItemBadge text={String(unreadMessageCount)} variant="filled" />
      ) : undefined,
    });
  } else {
    (appliedMessengerItem as any).badge = !!unreadMessageCount ? (
      <DrawerItemBadge text={String(unreadMessageCount)} variant="filled" />
    ) : undefined;
  }

  return items;
};

export const useLayoutProps = (): {
  connectedDrawerProps: DrawerProps;
  connectedHeaderProps: HeaderProps;
} => {
  const { user: auth0User } = useAuth0();
  const { isTalent, getData } = useCurrentUser();
  const drawerItems = useConnectedDrawerProps();
  const data = getData().data;
  const user = data?.currentTalent ?? data?.currentCompanyUser;

  const subscriptionSince = data?.currentTalentSubscription?.start_date;
  const memberSinceText = subscriptionSince
    ? `since ${format(parseISO(user?.created_at), 'yyyy')}`
    : '';

  const firstName = getFirstName(user) ?? auth0User?.given_name;
  const lastName = getLastName(user) ?? auth0User?.family_name;
  const isTalentMatcher = useIsPaidMatcherAccount();
  const isTalentMember = useIsPaidMemberAccount();
  const isLobbyTalent = getIsLobbyTalent(user as Talent);
  const isTalentVerified = !!useIsCurrentTalentVerified();

  const talentPosition = !isTalentVerified ? (
    <Chip
      label="Pending verification"
      size="small"
      style={{
        color: 'white',
        background: '#ED6C02',
        cursor: 'pointer',
      }}
    />
  ) : isLobbyTalent ? (
    <Chip
      label="Visitor account"
      icon={<CheckIcon style={{ width: 20 }} />}
      size="small"
      variant="outlined"
      style={{
        cursor: 'pointer',
      }}
      color="error"
    />
  ) : isTalentMember || isTalentMatcher ? (
    <Chip
      label={`Member ${memberSinceText}`}
      size="small"
      style={{
        color: 'white',
        background: '#336FEE',
        cursor: 'pointer',
      }}
    />
  ) : (
    //       : isTalentMatcher ? (
    //   <Chip
    //     label="Talent Matcher"
    //     size="small"
    //     style={{ background: '#F9F12E', color: '#000', cursor: 'pointer' }}
    //   />
    // )
    (user as Talent)?.source?.name
  );

  const accountProps = {
    name: formatName({ firstName, lastName }),
    avatar: (user as Talent)?.avatar?.avatar ?? auth0User?.picture,
    position: isTalent
      ? talentPosition
      : (user as User)?.company?.name || 'Company user',
  };
  const connectedDrawerProps = {
    items: drawerItems,
    isHidden: !isTalentVerified && isTalent,
  };

  const connectedHeaderProps: HeaderProps = {
    accountProps,
    inventionCount: 1,
  };

  return { connectedHeaderProps, connectedDrawerProps };
};
