import React, { useRef, useState } from 'react';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Skeleton } from '@mui/lab';
import { Badge, IconButton, Popover, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useGetNotificationsQuery } from '@libs/graphql-types';

import { ImportantNotificationsPopover } from './ImportantNotificationsPopover';
import NotificationsList from './NotificationsList';

interface NotificationsProps {
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#707070',
  },
  badge: {
    minWidth: 12,
    height: 20,
    width: 20,
    fontSize: 12,
    lineHeight: '20px',
    padding: '0 4px',
    backgroundColor: '#F7EF2E',
  },
  skeleton: {
    backgroundColor: 'white',
    opacity: 0.1,
  },
  popover: {
    top: '65px !important',
    right: 0,
    left: 'auto !important',
  },
}));

const Notifications = (props: NotificationsProps) => {
  const ref = useRef<any>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isLoading } = props;
  const classes = useStyles(props);

  const { data, loading } = useGetNotificationsQuery();
  const count = data?.currentUserUnreadNotificationsCount || 0;
  const importantCount =
    data?.unreadImportantNotificationsCount?.paginatorInfo?.total || 0;
  const notifications = data?.currentUserNotifications?.data || [];

  if (isLoading || loading) {
    return (
      <Skeleton
        className={classes.skeleton}
        variant="circular"
        width={30}
        height={30}
      />
    );
  }

  return (
    <div>
      <ImportantNotificationsPopover count={importantCount}>
        <IconButton
          className={classes.root}
          onClick={() => setIsMenuOpen((s) => !s)}
          ref={ref as any}
          size="medium"
          data-test-id="notifications-btn"
        >
          <Badge
            classes={{ badge: classes.badge }}
            showZero
            color="primary"
            invisible={count < 1}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={count}
          >
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
      </ImportantNotificationsPopover>

      {ref.current && (
        <Popover
          PaperProps={{
            square: true,
          }}
          TransitionComponent={Fade}
          classes={{ paper: classes.popover }}
          open={isMenuOpen}
          anchorEl={ref.current}
          onClose={() => setIsMenuOpen(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <NotificationsList notifications={notifications} />
        </Popover>
      )}
    </div>
  );
};

export default Notifications;
