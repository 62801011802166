import FormikAutoSaving from 'components/form/formik/FormikAutoSave';
import { ConnectedPageLayout } from 'components/layout/page-layout';
import { Formik } from 'formik';
import useMediaQueries from 'hooks/common/useMediaQueries';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useToggle } from 'react-use';
import { useLoadTabsCount } from 'screens/talent-matcher/job-board/content/hooks';
import { ViewTypes } from 'screens/talent-matcher/job-board/types';
import { noop } from 'utils/common';

import { Box, Grid, SwipeableDrawer } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

import Content from './content';
import Filter from './filter';
import { useAutoSaveHandler, useInitialValues } from './hooks';

interface JobBoardProps extends RouteComponentProps {}

const useStyles = makeStyles((theme) => ({
  settingsDrawer: {
    position: 'static',
    borderLeft: 'none',

    [theme.breakpoints.down('lg')]: {
      position: 'fixed',
      width: '40%',
    },
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      width: '100%',
    },
  },
  contentWrapper: {},
}));

const JobBoard = (props: JobBoardProps) => {
  const classes = useStyles();
  const saveToQuery = useAutoSaveHandler();
  const initialValues = useInitialValues();
  const { isMD } = useMediaQueries();

  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle(false);
  const openDrawer = () => toggleIsDrawerOpen(true);
  const {
    applicationsCount,
    savedCount,
    recommendedCount,
    openCount,
    refetchJobCounts,
    externalCount,
  } = useLoadTabsCount(ViewTypes.Talent);

  return (
    <ConnectedPageLayout
      documentTitle="Job board"
      drawerProps={{}}
      headerProps={{ accountProps: {} }}
      classes={{ contentWrapper: classes.contentWrapper }}
    >
      <Formik initialValues={initialValues} onSubmit={noop}>
        {({ values }) => {
          const finalOpenCount = values.jobSourceInternal ? openCount : 0;
          return (
            <Grid
              container
              wrap="nowrap"
              direction={isMD ? 'column' : 'row'}
              spacing={4}
            >
              <FormikAutoSaving onValuesChange={saveToQuery} />
              <Grid style={{ minWidth: 320 }} item>
                <Box mb={6}>
                  <Typography fontWeight={500} variant="h5">
                    Job Board
                  </Typography>
                </Box>

                <SwipeableDrawer
                  classes={{
                    paper: classes.settingsDrawer,
                  }}
                  anchor="right"
                  variant={isMD ? 'temporary' : 'permanent'}
                  open={isDrawerOpen}
                  onClose={() => toggleIsDrawerOpen(false)}
                  onOpen={openDrawer}
                >
                  <Filter
                    toggleDrawer={toggleIsDrawerOpen}
                    openCount={openCount}
                    externalCount={externalCount}
                  />
                </SwipeableDrawer>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Content
                  openDrawer={openDrawer}
                  applicationsCount={applicationsCount}
                  savedCount={savedCount}
                  recommendedCount={recommendedCount}
                  refetchJobCounts={refetchJobCounts}
                  totalCount={finalOpenCount || undefined}
                />
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </ConnectedPageLayout>
  );
};

export default JobBoard;
