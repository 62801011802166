import { useMutation } from '@apollo/client';
import { DECLINE_TALENT_ACCOUNT } from 'graphql/talents';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import React from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Mutation,
  MutationVerifyTalentAccountArgs,
  Talent,
} from '@libs/graphql-types';

const useStyles = makeStyles(() => ({
  dialogClass: {
    padding: '40px',

    '& > .MuiDialogTitle-root, .MuiDialogContent-root': {
      padding: 0,
    },

    '& > .MuiDialogActions-root': {
      justifyContent: 'center',
      flexDirection: 'row-reverse',

      '& > button': {
        width: '220px',

        '&:not(:first-child)': {
          marginLeft: '0',
        },
        '&:first-child': {
          marginLeft: '24px',
        },
      },
    },
  },
  dialogTitle: {
    padding: 0,
    maxWidth: '325px',
    width: '100%',
    margin: '0 auto 24px auto',
    textAlign: 'center',
    lineHeight: '24px',
  },
  dialogContent: {
    padding: 0,
    maxWidth: '325px',
    width: '100%',
    margin: '0 auto 16px auto',
    textAlign: 'center',
    lineHeight: '20px',
  },
}));

export const useRevokeInviteAccount = (
  talent: Talent,
  refetch?: VoidFunction,
) => {
  const confirm = useConfirm();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [decline, { loading }] = useMutation<
    Mutation,
    MutationVerifyTalentAccountArgs
  >(DECLINE_TALENT_ACCOUNT, {
    onCompleted: () => {
      enqueueSnackbar('Talent invitation was revoked', {
        variant: 'warning',
      });
      if (refetch) {
        refetch();
      }
    },
  });

  const revokeInvite = async () => {
    await confirm({
      confirmationText: 'Revoke',
      content: (
        <Typography variant="subtitle2" className={classes.dialogContent}>
          Do you want to revoke invite from {talent.email_abac?.value}?
        </Typography>
      ),
      title: (
        <Typography variant="h6" className={classes.dialogTitle}>
          Revoke invite
        </Typography>
      ),
      dialogProps: {
        classes: {
          paper: classes.dialogClass,
        },
      },
      cancellationButtonProps: {
        variant: 'outlined',
        color: 'secondary',
      },
    });

    await decline({ variables: { talent_id: talent.id } });
  };

  return { revokeInvite, loading };
};
