import { Grid } from '@mui/material';

import { Job, JobLocationTypeEnum, Skill } from '@libs/graphql-types';
import { isNil } from '@libs/helpers/common';
import { SkillChip } from '@libs/ui/components/chip';
import {
  JOB_TYPE_MAP,
  formatRate,
  formatDate,
  getJobCapacity,
} from '@libs/ui/components/job/utils';

import { DataGridItem } from '../job-info/types';

export const mapJobToDataGridItems = (job?: Required<Job>): DataGridItem[] => {
  const isHibryd = job?.location_type === JobLocationTypeEnum.Hybrid;
  const daysInOffice: DataGridItem = {
    icon: 'period',
    title: 'Days in office',
    value: job?.office_hours_per_month,
  };
  const items: DataGridItem[] = [
    {
      title: 'Client',
      icon: 'client',
      value: job?.client || '-',
    },
    {
      icon: 'location',
      title: 'Country',
      value: job?.country || '-',
    },
    {
      icon: 'location',
      title: 'City',
      value: job?.city || '-',
    },

    {
      title: 'Type',
      value:
        JOB_TYPE_MAP[job?.location_type as keyof typeof JOB_TYPE_MAP] || '-',
      icon: 'calendar',
    },
    ...(isHibryd ? [daysInOffice] : []),
    {
      icon: 'euro',
      title: 'Hourly rate range',
      value: formatRate({
        min: job?.rate_min,
        max: job?.rate_max,
        period: 'hour',
        isNegotiable: job?.is_rate_negotiable,
      }),
    },
    {
      icon: 'period',
      title: 'Starting date',
      value: job?.posted_at ? formatDate(job?.start_date) : '',
    },
    {
      icon: 'period',
      title: 'End date',
      value: job?.end_date ? formatDate(job?.end_date) : '-',
    },
    {
      title: 'Required Capacity',
      value: getJobCapacity(job as Job),
      icon: 'timelapse',
    },
    {
      icon: 'book',
      title: 'Key skills',
      value: (
        <Grid container spacing={2}>
          {job?.skills?.map((skill) => (
            <Grid key={skill?.id} item>
              <SkillChip
                style={{
                  maxWidth: 240,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                size="small"
                skill={skill as Skill}
              />
            </Grid>
          ))}
        </Grid>
      ),
    },
  ];

  return items.filter((item) => !isNil(item.value));
};
