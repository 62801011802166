import { Formik, FormikErrors, getIn } from 'formik';
import React, { useState } from 'react';
import {
  DefaultModalProps,
  useOpenModal,
  withLocationStateModal,
} from 'utils/modals';
import * as yup from 'yup';

import { Job, JobTypeEnum } from '@libs/graphql-types';
import { modelPath } from '@libs/helpers/form';

import { useSubmitAction } from './hooks';
import { ProposalStep1 } from './steps/ProposalStep1';
import { ProposalStep2 } from './steps/ProposalStep2';
import { ProposalStep3 } from './steps/ProposalStep3';
import { ProposalStep4 } from './steps/ProposalStep4';
import { ProposalStep5 } from './steps/ProposalStep5';
import { FormikState } from './types';

type OwnProps = {
  onSuccess: VoidFunction;
  job: Job;
};

interface CandidatProposalModalProps extends DefaultModalProps<OwnProps> {
  onSuccess: VoidFunction;
  job: Job;
}

const STEPS = [
  {
    path: modelPath<FormikState>((m) => m.step1),
    Component: ProposalStep1,
  },
  {
    path: modelPath<FormikState>((m) => m.step2),
    Component: ProposalStep2,
  },
  {
    path: modelPath<FormikState>((m) => m.step3),
    Component: ProposalStep3,
  },
  {
    path: modelPath<FormikState>((m) => m.step4),
    Component: ProposalStep4,
  },
  {
    path: modelPath<FormikState>((m) => m.step5),
    Component: ProposalStep5,
  },
];

const getFaliedStep = (errors: FormikErrors<FormikState>) => {
  const failedStep = STEPS.find(({ path }) => {
    return path in errors;
  });
  const failedStepIndex = STEPS.findIndex(({ path }) => {
    return path in errors;
  });
  return { failedStep, failedStepIndex };
};

const getValidator = (job: Job) => {
  const isFreelanceJob = job.type === JobTypeEnum.Freelance;

  return yup.object().shape({
    step2: yup.object().shape({
      first_name: yup.string().trim().required(),
      last_name: yup.string().trim().required(),
      email: yup.string().email().trim().required(),
      rate: isFreelanceJob ? yup.number().required() : yup.mixed(),
      salary: !isFreelanceJob ? yup.number().required() : yup.mixed(),
    }),
    step3: yup.object().shape({
      cv: yup.array().min(1).required(),
    }),
    step4: yup.object().shape({
      motivation_letter: yup.array(),
    }),
    step5: yup.object().shape({
      screening_questionnaire: yup.array(),
    }),
  });
};

export const CandidateProposalModalComponent = ({
  isOpen,
  close,
  job,
}: CandidatProposalModalProps) => {
  const [currentStep, setCurrentStep] = useState(3);
  const { onSubmit, loading } = useSubmitAction({
    jobId: job.id,
    onSuccess: () => {
      close();
    },
  });

  if (!isOpen) {
    return null;
  }

  return (
    <Formik<FormikState>
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        step1: {},
        step2: {
          first_name: '',
          last_name: '',
          email: '',
        },
        step3: {
          cv: [],
        },
        step4: {
          motivation_letter: [],
        },
        step5: {
          screening_questionnaire: [],
        },
      }}
      validationSchema={getValidator(job)}
    >
      {({ submitForm, errors }) => {
        const { Component: StepComponent, path } = STEPS[currentStep];
        const { failedStep, failedStepIndex } = getFaliedStep(errors);
        const isItLastStep = currentStep === STEPS.length - 1;
        const isStepValid = !Object.keys(getIn(errors, path) || {}).length;

        const onSubmit = isItLastStep
          ? submitForm
          : () => setCurrentStep(currentStep + 1);

        const onBack =
          currentStep === 0 ? close : () => setCurrentStep(currentStep - 1);

        if (failedStep && failedStepIndex < currentStep) {
          setCurrentStep(STEPS.indexOf(failedStep));
        }

        return (
          <>
            <StepComponent
              close={close}
              onSubmit={onSubmit}
              onBack={onBack}
              isValid={isStepValid}
              loading={loading}
              job={job}
            />
          </>
        );
      }}
    </Formik>
  );
};

CandidateProposalModalComponent.id = 'CandidatProposalModalComponent';

export const CandidatrProposalModal = withLocationStateModal<OwnProps>({
  id: CandidateProposalModalComponent.id,
})(CandidateProposalModalComponent);

export const useOpenCandidateProposalModalComponent = () =>
  useOpenModal(CandidateProposalModalComponent.id);
