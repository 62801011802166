import { DEFAULT_AVATAR } from 'consts/common';
import React from 'react';
import { formatName } from 'utils/talent';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { Avatar, Box, Grid } from '@mui/material';

import { JobMatch } from '@libs/graphql-types';
import Button from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

import useStyles from './styles';
import { SubmittedTalentActions } from './submitted-talent-actions';

interface TalentCardProps {
  match: Partial<JobMatch>;
  showActions?: boolean;
  refetch?: () => void;
}

export const SubmittedTalentCard = (props: TalentCardProps) => {
  const { match, showActions = false, refetch } = props;
  const classes = useStyles(props);
  const avatar = DEFAULT_AVATAR;
  const name = formatName({
    firstName:
      match?.job_suggestion?.first_name ||
      match?.job_suggestion?.talent?.first_name_abac?.value,
    lastName:
      match?.job_suggestion?.last_name ||
      match?.job_suggestion?.talent?.last_name_abac?.value,
  });
  const suggester = match.job_suggestion?.suggested_by_talent;
  const proposedBy = suggester
    ? formatName({
        firstName: suggester?.first_name_abac?.value,
        lastName: suggester?.last_name_abac?.value,
      })
    : 'Recrouter';

  const cv = match?.job_suggestion?.cv_attachment;
  const motivationLetter = match?.job_suggestion?.motivation_letter_attachment;
  const screenAttachment =
    match?.job_suggestion?.screening_questionnaire_attachment;
  const hasDocs = Boolean(cv || motivationLetter || screenAttachment);

  const rejectionMessage = match?.job_match_declination?.message;

  return (
    <div onClick={(e) => e.stopPropagation()} className={classes.container}>
      <Grid container spacing={5} wrap="nowrap">
        <Grid item>
          <Avatar className={classes.avatar} src={avatar} />
        </Grid>
        <Grid item flexGrow={1} className={classes.mainInfo}>
          <Grid direction="column" container className={classes.fullWidth}>
            <Grid item className={classes.fullWidth}>
              <Typography variant="h6" className={classes.name} title={name}>
                {name}
              </Typography>
            </Grid>
            {proposedBy && (
              <Grid item className={classes.fullWidth}>
                <Typography variant="body2" color="textSecondary">
                  Proposed by: {proposedBy}
                </Typography>
              </Grid>
            )}

            {rejectionMessage && (
              <Grid item className={classes.fullWidth}>
                <Typography variant="subtitle2" color="textSecondary">
                  <Typography
                    component="span"
                    color="error"
                    variant="subtitle2"
                    fontWeight={500}
                  >
                    Rejection reason:
                  </Typography>{' '}
                  {rejectionMessage}
                </Typography>
              </Grid>
            )}

            {hasDocs && (
              <Grid item className={classes.fullWidth} sx={{ mt: 2 }}>
                <Box mb={-1}>
                  <Typography variant="subtitle2">Documents</Typography>
                </Box>

                <Grid container>
                  {cv && (
                    <Grid item>
                      <Button
                        startIcon={<DownloadOutlinedIcon />}
                        color="info"
                        href={cv.url}
                        {...{ download: cv.title, target: '_blank' }}
                      >
                        CV
                      </Button>
                    </Grid>
                  )}
                  {motivationLetter && (
                    <Grid item>
                      <Button
                        startIcon={<DownloadOutlinedIcon />}
                        color="info"
                        href={motivationLetter.url}
                        {...{
                          download: motivationLetter.title,
                          target: '_blank',
                        }}
                      >
                        Motivation letter
                      </Button>
                    </Grid>
                  )}
                  {screenAttachment && (
                    <Grid item>
                      <Button
                        startIcon={<DownloadOutlinedIcon />}
                        color="info"
                        href={screenAttachment.url}
                        {...{
                          download: screenAttachment.title,
                          target: '_blank',
                        }}
                      >
                        Screening questionnaire
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {showActions && (
          <Grid item>
            <SubmittedTalentActions
              match={match as JobMatch}
              refetch={refetch}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
