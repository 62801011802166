import { gql } from '@apollo/client';

export default gql`
  fragment FullTalent on Talent {
    number_of_active_matcher_assignments
    currency
    company_pool_connections {
      company {
        id
        name
      }
      status
      initiator
      cancel_initiator
    }

    join_reason
    stream_chat_id
    origin
    stream_chat_token
    default_company_referral_key
    first_name_abac {
      value
    }
    last_name_abac {
      value
    }
    available_date_updated_at
    account_type
    address
    location
    recent_position_title
    about
    id
    created_at
    rate
    rate_min
    rate_max
    salary
    is_invitation_accepted
    is_verification_required
    is_matcher
    email_abac {
      value
    }
    available_date
    hours_per_week
    available_now
    is_ot_pool
    reminded_at
    account_settings {
      receive_company_and_product_updates
      receive_direct_messages
      instant_matches_notifications_min_score
    }
    companies {
      id
      name
    }
    category {
      id
      name
      slug
      created_at
      updated_at
    }
    subcategories {
      id
      name
    }
    source {
      id
      name
      logo
      created_at
      updated_at
    }
    avatar {
      avatar
      hash
    }
    documents {
      title
      url
      size
      content_type
      hash
      created_at
      updated_at
    }
    talent_data {
      facebook_profile_link
      linkedin_profile_link_abac {
        value
      }
      upwork_profile_link
      phone
      vat_number
    }
    invited_by {
      company_name
      first_name_abac {
        value
      }
      last_name_abac {
        value
      }
      invitation_type
      id
    }
    talent_work_history {
      id
      position_title
      company_name
      worked_from
      worked_to
      created_at
      updated_at
    }
    documents {
      title
      url
      size
      content_type
      hash
      created_at
      updated_at
    }
    skills(first: 50) {
      data {
        id
        skill_type: skill_type
        name
      }
    }
  }
`;
