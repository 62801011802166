import { format, parseISO } from 'date-fns';
import React from 'react';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Grid, Box, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Typography from '@libs/ui/components/typography';

import NotificationActions from './NotificationActions';
import NotificationMessage from './NotificationMessage';
import NotificationReplyLink from './NotificationReplyLink';
import { NotificationItemType } from './utils';

interface NotificationItemProps {
  notification: NotificationItemType;
  changeStatus: ({
    status,
    notification,
  }: {
    status: boolean;
    notification: NotificationItemType;
    notify?: boolean;
  }) => void;
  deleteNotification: (notification: NotificationItemType) => void;
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
  },
  dot: {
    '& svg': {
      fontSize: 14,
      color: ({ notification }: { notification: NotificationItemType }) =>
        notification.is_read
          ? theme.palette.grey[400]
          : theme.palette.tertiary.main,
    },
  },
  content: {
    flexGrow: 1,
  },
  actions: {},
}));

const NotificationItem = ({
  notification,
  isLoading,
  changeStatus,
  deleteNotification,
}: NotificationItemProps) => {
  const classes = useStyles({ notification });

  return (
    <Box
      data-test-id="header-notification-item"
      className={classes.root}
      p={2}
      mb={1}
    >
      <Grid wrap="nowrap" spacing={2} container alignItems="center">
        <Grid className={classes.dot} item>
          <Tooltip title={`Mark as${notification?.is_read ? '' : ' not'} read`}>
            <div>
              <IconButton
                onClick={() =>
                  changeStatus({
                    status: !notification.is_read,
                    notification,
                    notify: true,
                  })
                }
                disabled={isLoading}
                size="small"
              >
                <FiberManualRecordIcon fontSize="small" />
              </IconButton>
            </div>
          </Tooltip>
        </Grid>
        <Grid className={classes.content} item>
          {notification.title && (
            <Typography fontWeight={500} variant="body2">
              {notification.title}
            </Typography>
          )}
          <Typography variant="body2">
            <NotificationMessage
              handleChangeStatus={() =>
                changeStatus({ status: true, notification })
              }
              message={notification.message}
            />
          </Typography>
          {notification.reply_to && (
            <NotificationReplyLink notification={notification} />
          )}
          <Box pt={1}>
            <Typography variant="caption" color="textSecondary">
              {format(parseISO(notification.created_at), 'MMMM dd')}
            </Typography>
          </Box>
        </Grid>
        <Grid className={classes.actions} item>
          <NotificationActions
            handleChangeStatus={() =>
              changeStatus({ status: true, notification })
            }
            deleteNotification={() => deleteNotification(notification)}
            notification={notification}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotificationItem;
