import {
  AccountTypeEnum,
  Talent,
  TalentCompanyPoolingStatusEnum,
  TalentOriginEnum,
  User,
} from '@libs/graphql-types';

interface FormatNameArgs {
  firstName?: string;
  lastName?: string;
  hideFullName?: boolean;
}

export const formatName = ({
  firstName = '',
  lastName = '',
  hideFullName = false,
}: FormatNameArgs): string => {
  const lastNameFirstLetter = lastName?.[0] || '';
  const finalLastName = hideFullName ? `${lastNameFirstLetter}...` : lastName;
  const nameArr = [firstName, finalLastName];

  return nameArr.some(Boolean) ? nameArr.filter(Boolean).join(' ') : '';
};

export const getIsVerifiedTalent = (talent?: Talent): boolean => {
  const hasAnyActiveConnections = talent?.company_pool_connections?.some(
    (i) => i?.status === TalentCompanyPoolingStatusEnum.Approved,
  );

  return !!hasAnyActiveConnections;
};

export const needToShowTalentBlueTick = (talent: Talent): boolean => {
  const showBlueTick =
    !!talent?.is_invitation_accepted && getIsVerifiedTalent(talent);

  return showBlueTick;
};

const LOBBY_ORIGINS: TalentOriginEnum[] = [
  TalentOriginEnum.MainLanding,
  TalentOriginEnum.ExternalJobPageApplication,
];

export const getIsLobbyTalent = (talent?: Talent): boolean => {
  const result =
    !!talent?.origin &&
    LOBBY_ORIGINS.includes(talent?.origin) &&
    talent?.account_type === AccountTypeEnum.Free;

  return result;
};

export const isTalentType = (user: any): user is Talent => {
  return user && user.__typename === 'Talent';
};

export const getFirstName = (
  user: Talent | User | null | undefined,
): string | undefined => {
  if (!user) return undefined;
  return isTalentType(user) ? user.first_name_abac?.value : user.first_name;
};

export const getLastName = (
  user: Talent | User | null | undefined,
): string | undefined => {
  if (!user) return undefined;
  return isTalentType(user) ? user.last_name_abac?.value : user.last_name;
};

export const getEmail = (
  user: Talent | User | null | undefined,
): string | undefined => {
  if (!user) return undefined;
  return isTalentType(user) ? user.email_abac?.value : user.email;
};
